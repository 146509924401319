// Generated by Framer (a1a9a9e)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["BB3Fc86xb", "kUIrID9_Q", "j4Ghzpj4J", "T1GIQ9Nry", "enk_1JB1J"];

const serializationHash = "framer-z60kZ"

const variantClassNames = {BB3Fc86xb: "framer-v-1soft12", enk_1JB1J: "framer-v-1pj3w7h", j4Ghzpj4J: "framer-v-5xe3vo", kUIrID9_Q: "framer-v-1bqe45q", T1GIQ9Nry: "framer-v-1hjd2pp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.35, ease: [0, 0, 1, 1], type: "tween"}

const transition2 = {delay: 0, duration: 1, ease: [0, 0, 1, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "BB3Fc86xb", "Variant 2": "kUIrID9_Q", "Variant 3": "j4Ghzpj4J", "Variant 4": "T1GIQ9Nry", "Variant 5": "enk_1JB1J"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "BB3Fc86xb"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "BB3Fc86xb", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1w2boo1 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("kUIrID9_Q"), 1)
})

const onAppearbjx1q = activeVariantCallback(async (...args) => {
await delay(() => setVariant("j4Ghzpj4J"), 1000)
})

const onAppear1k3xuca = activeVariantCallback(async (...args) => {
await delay(() => setVariant("T1GIQ9Nry"), 350)
})

const onAppearr11ixc = activeVariantCallback(async (...args) => {
await delay(() => setVariant("enk_1JB1J"), 1000)
})

const onAppear12arktm = activeVariantCallback(async (...args) => {
await delay(() => setVariant("kUIrID9_Q"), 350)
})

useOnVariantChange(baseVariant, {default: onAppear1w2boo1, enk_1JB1J: onAppear12arktm, j4Ghzpj4J: onAppear1k3xuca, kUIrID9_Q: onAppearbjx1q, T1GIQ9Nry: onAppearr11ixc})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({kUIrID9_Q: {value: transition2}, T1GIQ9Nry: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1soft12", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"BB3Fc86xb"} ref={ref ?? ref1} style={{backgroundColor: "rgba(160, 171, 251, 0.18)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} {...addPropertyOverrides({enk_1JB1J: {"data-framer-name": "Variant 5"}, j4Ghzpj4J: {"data-framer-name": "Variant 3"}, kUIrID9_Q: {"data-framer-name": "Variant 2"}, T1GIQ9Nry: {"data-framer-name": "Variant 4"}}, baseVariant, gestureVariant)}><motion.div className={"framer-jb9paa"} data-framer-name={"Moving Light"} layoutDependency={layoutDependency} layoutId={"cxKUWxF1V"} style={{background: "radial-gradient(50% 50% at 50% 50%, rgb(206, 212, 253) 29.72972972972973%, rgba(255, 255, 255, 0) 100%)"}}/><motion.div className={"framer-ejcm2c"} data-framer-name={"Container"} layoutDependency={layoutDependency} layoutId={"vpFuwYzzL"} style={{background: "linear-gradient(180deg, var(--token-8a213278-31dd-424d-8fb4-5da7d0d1f079, rgb(25, 78, 255)) /* {\"name\":\"Blue 55\"} */ 0%, var(--token-e6711ee2-0d80-49b0-8030-a95cbf836a37, rgb(0, 59, 255)) /* {\"name\":\"Blue 50\"} */ 100%)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-z60kZ.framer-8t8zny, .framer-z60kZ .framer-8t8zny { display: block; }", ".framer-z60kZ.framer-1soft12 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 45px; justify-content: center; overflow: hidden; padding: 1px; position: relative; width: 182px; will-change: var(--framer-will-change-override, transform); }", ".framer-z60kZ .framer-jb9paa { flex: none; height: 44px; left: -17px; overflow: visible; position: absolute; top: -20px; width: 70px; z-index: 1; }", ".framer-z60kZ .framer-ejcm2c { flex: 1 0 0px; height: 1px; overflow: visible; position: relative; width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-z60kZ.framer-1soft12 { gap: 0px; } .framer-z60kZ.framer-1soft12 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-z60kZ.framer-1soft12 > :first-child { margin-top: 0px; } .framer-z60kZ.framer-1soft12 > :last-child { margin-bottom: 0px; } }", ".framer-z60kZ.framer-v-1bqe45q .framer-jb9paa { left: unset; right: -20px; }", ".framer-z60kZ.framer-v-5xe3vo .framer-jb9paa { bottom: -20px; left: unset; right: -20px; top: unset; }", ".framer-z60kZ.framer-v-1hjd2pp .framer-jb9paa { bottom: -20px; left: -20px; top: unset; width: 50px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 45
 * @framerIntrinsicWidth 182
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"kUIrID9_Q":{"layout":["fixed","fixed"]},"j4Ghzpj4J":{"layout":["fixed","fixed"]},"T1GIQ9Nry":{"layout":["fixed","fixed"]},"enk_1JB1J":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLGOAJfO5D: React.ComponentType<Props> = withCSS(Component, css, "framer-z60kZ") as typeof Component;
export default FramerLGOAJfO5D;

FramerLGOAJfO5D.displayName = "Helper/Moving BG 2";

FramerLGOAJfO5D.defaultProps = {height: 45, width: 182};

addPropertyControls(FramerLGOAJfO5D, {variant: {options: ["BB3Fc86xb", "kUIrID9_Q", "j4Ghzpj4J", "T1GIQ9Nry", "enk_1JB1J"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4", "Variant 5"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerLGOAJfO5D, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})